<template>
  <q-dialog v-model="show" persistent>
    <q-card class="q-dialog-plugin" style="min-width: 350px">
      <q-card-section>
        <div class="text-body1">{{ $t('激活账号') }}</div>
        <div class="text-body2">{{ $t('请输入邀请码进行授权激活') }}</div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        <div class="input-wrap">
          <q-input borderless v-model="reAddress" dense :input-style="{ color: '#fff' }" style="width: 100%;"
            :placeholder="$t('请输入邀请码')" />
        </div>
        <!-- <div class="row items-center q-mt-sm">
          <div>区域：</div>
          <q-radio v-model="tp" :val="1" label="A区" color="warning" dark class="q-mr-sm" />
          <q-radio v-model="tp" :val="2" label="B区" color="warning" dark />
        </div> -->
      </q-card-section>
      <div style="padding: 0 16px 16px;">
        <div class="m-btn" @click="register">{{ $t('注册') }}</div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref } from 'vue'
import Web3Helper from "../assets/js/web3helper.js";
import CustomComponent from "@/components/CustomComponent";

export default {
  name: '',
  components: {},
  props: {
    autoShowRegisterL: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    return {
      show: ref(false),
      web3Helper: ref({}),
      reAddress: ref(""),
      address: ref(""),
      us: ref(null),
      fname: ref(""),
      tp: ref(1)
    }
  },
  created() {
    if (this.$route.query.reAddress) {
      this.reAddress = this.$route.query.reAddress;
    }
    if (this.$route.query.tp) {
      this.tp = this.$route.query.tp;
    }
    let loginState = this.$utils.getloc("loginState")
    if(!loginState || loginState == 0) {
      this.getAddress()
    }
  },
  methods: {
    getAddress() {
      let _this = this;
      _this.web3Helper = new Web3Helper();
      _this.web3Helper.getAddress().then((address) => {
        _this.address = address;
        let loginCount = _this.$utils.getloc("loginCount")
        if (loginCount == 0 || !loginCount) {
          _this.login();
        }
      }, (err) => {
        alert(_this.$i18n.t("获取地址错误") + err.message);
      });
    },
    login() {
      let _this = this;
      _this.$request.post(
        "Api/Users/Login",
        {
          address: _this.address,
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            })
            return
          }
          if (res.data.code == -1) {
            _this.show = true;
            return;
          }
          let data = res.data.data;
          _this.$utils.setloc("token", data.token);
          _this.$utils.setloc("us", JSON.stringify(data.users));
          this.$utils.setloc('loginState', 1)
          _this.$emit("refresh")
        }
      );
    },
    register() {
      let _this = this;
      let cacheToken = Math.random();
      try {
        _this.web3Helper.getSign().then((signStr) => {
          this.$q.loading.show({
          message: _this.$i18n.t("注册中...")
        })
          _this.$request.post(
            "api/Users/Register", {
            cacheToken: cacheToken,
            recode: _this.reAddress,
            address: _this.address,
            signStr: signStr,
            tp: _this.tp
          },
            (res) => {
              _this.$q.loading.hide();
              if (res.data.code == 0) {
                _this.$q.dialog({
                  component: CustomComponent,
                  componentProps: {
                    messages: [res.data.msg],
                    persistent: true,
                  }
                })
                return
              }
              _this.show = false
              _this.login()
            });
        }).catch((error) => {
          alert(`error:${error}`);
        })
      } catch (error) {
        alert(`error:${error}`);
      }
    },
  }
}
</script>

<style scoped>
.q-dialog-plugin {
  background: #1b1b1b;
  color: #fff;
}

.text-body1 {
  font-size: 16px;
}

.text-body2 {
  font-size: 14px;
  margin-top: 10px;
}

.input-wrap {
  padding: 0 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #111;
}

.btn {
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  background: linear-gradient(90deg, #f56ef9 0, #8a6dff);
  border-radius: 10px;
}
</style>